import * as Sentry from '@sentry/nextjs'
import { useStytch } from '@stytch/nextjs'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useState } from 'react'

import Auth from '@components/auth'
import Navigation from '@components/navigation'
import EnvironmentContext from '@context/environment'
import UserContext from '@context/user'
import { EnvironmentSelected } from '@lib/analytics'
import api from '@lib/api'

import {
  ContentWrapper,
  DashboardContentAlert,
  DashboardContentAlertEmpty,
  DashboardContentWrapper,
  DashboardWrapper,
} from './style'

const Page: FunctionComponent<PageComponentProps> = ({
  children,
  isAuthed = false,
  isDashboard = false,
}) => {
  // Derive state.
  const [userData, setUserData] = useState<UserData>(null)
  const [selectedEnvironment, setSelectedEnvironment] =
    useState<Environment>(null)
  const [isActiveMenu, setIsActiveMenu] = useState<boolean>(false)

  // Derive router.
  const router = useRouter()

  // Derive Stytch.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
  const stytch = useStytch()

  const getUserData = async () => {
    try {
      const user = await api.users.getMe()

      if (isAuthed && !user) {
        Cookies.remove('environmentId')
        await api.auth.logout()
        await router.push('/login')
      }

      setUserData(user)

      // If the user has no environments, don't set an environment.
      if (!user.custodian.environments.length) {
        Cookies.remove('environmentId')
        return
      }

      // If the user has no selected environment, set the "Development" environment.
      const environmentId = Cookies.get('environmentId')
      if (!environmentId) {
        const environment = user.custodian.environments.find(
          (env) => env.name === 'Development',
        )
        if (environment) {
          setSelectedEnvironment(environment)
        }
        return
      }

      // If the user has a selected environment, set it.
      const environment = user.custodian.environments.find(
        (env) => env.id === environmentId,
      )
      if (environment) setSelectedEnvironment(environment)
      else {
        const environment = user.custodian.environments.find(
          (env) => env.name === 'Development',
        )
        if (environment) {
          setSelectedEnvironment(environment)
        }
      }
    } catch (error) {
      console.error(`Error getting user data: `, error)
      Sentry.captureException(error)

      if (isAuthed) {
        await api.auth.logout()
        await router.push('/login')
      }
    }
  }

  useEffect(() => {
    if (isAuthed && stytch) {
      getUserData() // eslint-disable-line @typescript-eslint/no-floating-promises
    }
  }, [isAuthed, stytch])

  return (
    <UserContext.Provider value={{ user: userData, setUser: setUserData }}>
      <EnvironmentContext.Provider
        value={{
          environment: selectedEnvironment,
          setEnvironment: (environment) => {
            // Set the environment state.
            setSelectedEnvironment(environment)

            // Set the environment cookie.
            Cookies.set('environmentId', environment.id)

            // Track the event.
            analytics.track(EnvironmentSelected, {
              environmentId: environment.id,
              environmentName: environment.name,
            })
          },
        }}
      >
        <Auth isAuthed={isAuthed}>
          <ContentWrapper
            className={`${isActiveMenu ? 'menu-active' : ''}`}
            isDashboard={isDashboard}
          >
            {isDashboard ? (
              <DashboardWrapper>
                <Navigation
                  activeMenu={isActiveMenu}
                  toggleActiveMenu={setIsActiveMenu}
                />
                <DashboardContentWrapper>
                  {selectedEnvironment?.name === 'Development' ? (
                    <DashboardContentAlert id="environment-development-banner">
                      <p>
                        You are using your <strong>Development</strong>{' '}
                        environment.
                      </p>
                    </DashboardContentAlert>
                  ) : (
                    <DashboardContentAlertEmpty />
                  )}
                  {children}
                </DashboardContentWrapper>
              </DashboardWrapper>
            ) : (
              <>{children}</>
            )}
          </ContentWrapper>
        </Auth>
      </EnvironmentContext.Provider>
    </UserContext.Provider>
  )
}

export default Page
