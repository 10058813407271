import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import Cookies from 'js-cookie'

import { buildConnectApiUrl, requestConfig } from '@lib/api/requests'

export const login = async (body: AuthBody): Promise<UserData> => {
  const { data: user } = await axios.post<UserData>(
    buildConnectApiUrl(`/app/auth/login`),
    body,
    requestConfig,
  )

  return user
}

export const logout = async (): Promise<void> => {
  await axios.post<null>(buildConnectApiUrl(`/app/auth/logout`), requestConfig)

  Cookies.remove('is_authed')

  return
}

export const magicLink = async (body: MagicLinkBody): Promise<void> => {
  try {
    await axios.post<MagicLinkResponse>(
      buildConnectApiUrl(`/app/auth/magic-link`),
      body,
      requestConfig,
    )
  } catch (error) {
    Sentry.captureException(error)

    if (error.response.status === 404) {
      throw new Error(error.response.data.error as string)
    }

    throw error
  }
}

export default {
  login,
  logout,
  magicLink,
}
