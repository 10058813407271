import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useState } from 'react'

import api from '@lib/api'

const Auth: FunctionComponent<AuthProps> = ({ children, isAuthed = false }) => {
  const router = useRouter()
  const [sessionValid, setSessionValid] = useState<boolean>(false)

  const handleAuthState = async (): Promise<void> => {
    const hasSession = Cookies.get('is_authed')

    // Verify session
    if (hasSession !== 'true') {
      // Tell the server to log out
      await api.auth.logout()

      // Redirect to login
      await router.replace('/login')
    } else if (isAuthed && hasSession) {
      setSessionValid(true)
    }
  }

  useEffect(() => {
    if (isAuthed) {
      // Call it once initially.
      void handleAuthState()

      // Set up the interval for checking session validity
      const sessionCheckInterval = setInterval(() => {
        void handleAuthState()
      }, 5000) // Check every 5 seconds.

      // Clean up the interval when the component is unmounted
      return () => clearInterval(sessionCheckInterval)
    }
  }, [isAuthed])

  return <>{(!isAuthed || sessionValid) && children}</>
}

export default Auth
